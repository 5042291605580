@import "../../node_modules/lenis/dist/lenis.css";
@import "../../node_modules/swiper/swiper-bundle.css";

@import "../../node_modules/overlayscrollbars/styles/overlayscrollbars.css";
@import "./modules/fluid-fonts";
@import "./modules/navbar";
@import "./modules/cookieyes";
@import "./modules/accordion";
@import "./modules/marquee";
@import "./modules/tooltip";
@import "./modules/pagination";
@import "./modules/forms";
@import "./modules/animation";
/* Scrollbar */
.os-scrollbar {
    --os-track-bg: #e4e4e4;
    --os-track-bg-hover: #e4e4e4;
    --os-track-bg-active: #e4e4e4;
    --os-handle-bg: #8dabbe;
    --os-handle-bg-hover: #8dabbe;
    --os-handle-bg-active: #8dabbe;
}
/* Research */
.section_research .research_list-item.hidden {
    order: 99999999;
}
.research_list-item-link:hover .research_list-item-image {
    transform: scale(1.1);
}

.subnav-nav ul li a.hovered {
    animation: underline-in 0.2s ease-out forwards;
}
.subnav-nav ul li a.hovered.leaving-hover {
    background-position: 100% 100%;
    animation: underline-out 0.2s ease-out forwards;
}
@keyframes underline-in {
    0% {
        background-size: 0% 1px;
    }
    100% {
        background-size: 100% 1px;
    }
}
@keyframes underline-out {
    0% {
        background-size: 100% 1px;
    }
    100% {
        background-size: 0% 1px;
    }
}
@media (max-width: 991px) {
    .section_research .research_list-item:nth-child(3n) {
        display: none !important;
    }
}
@media (min-width: 992px) {
    .section_research .research_list-item:nth-child(4n) {
        display: none !important;
    }
}
/* Research Single Post */
@media (min-width: 768px) and (max-width: 991px) {
    #related-articles .research-list_list-item:nth-child(2n) {
        display: none;
    }
}

/* Swipers */
.people-slider_navigation .swiper-button-disabled {
    opacity: 0 !important;
}
.js-sync-slider_content-swiper,
.js-sync-slider_content-swiper .swiper-wrapper {
    position: static !important;
}
.js-sync-slider_content-swiper .swiper-slide {
    height: auto;
}

.cases-slider_container .swiper-slide-active {
    z-index: 10;
    opacity: 1;
}
/* Filters */
[data-post-category].hidden {
    display: none !important;
}

/* Offerings */
@media (pointer: fine) and (min-width: 768px) {
    .offerings_card:hover img {
        transform: scale(1.1);
    }
    .offerings_card:hover .button {
        background-color: #e2ecf4;
    }
}

#what-we-offer .js-reveal-title div div {
    padding-bottom: 0.2rem;
}
@media (min-width: 1440px) {
    #what-we-offer .js-reveal-title div div {
        padding-bottom: 0.4rem;
    }
}
/* Newsletter */
.section_newsletter {
    opacity: 0;
    pointer-events: none;
}
.section_newsletter.available {
    opacity: 1;
    pointer-events: auto;
}

/* How we work */
.what-we-do_graph-list-item-content .button.with-icon:hover .button.is-icon,
.what-we-do_graph-list-item-content .button.with-icon.active .button.is-icon,
.hero_corner-cta:hover .button.is-icon,
.coaching_list-item .button.with-icon:hover .button.is-icon {
    background-color: #fff3;
}

/* Button's Hover */
.side-overlay_head .button.is-icon img {
    transition: 0.5s ease;
}
.side-overlay_head .button.is-icon:hover img {
    transform: scale(1.5);
}

/* Insights */
.spotlight_grid:hover .spotlight_image {
    transform: scale(1.1);
}
.spotlight_grid:hover .button.is-primary {
    background-color: #e2ecf2;
}

strong {
    font-family: "Twklausannepan 500", sans-serif;
    font-weight: 200;
}
.people_collection-list,
.impact_cards-wrapper,
.research_list,
.people-slider_filters-list {
    -ms-overflow-style: none !important; /* Internet Explorer 10+ */
    scrollbar-width: none !important; /* Firefox */
}
.people-slider_filters-list::-webkit-scrollbar,
.research_list::-webkit-scrollbar,
.people_collection-list::-webkit-scrollbar,
.impact_cards-wrapper::-webkit-scrollbar {
    display: none !important; /* Safari and Chrome */
}

.side-overlay_content h1 {
    font-size: 1.5rem;
    line-height: 2rem;
}
@media (min-width: 992px) {
    .side-overlay_content h1 {
        font-size: 2rem;
        line-height: 2.5rem;
    }
}
@media (min-width: 1440px) {
    .side-overlay_content h1 {
        font-size: 2.222.vw;
        line-height: 2.778vw;
    }
}
ul + p {
    margin-top: 0;
}
ul li ul {
    margin-bottom: 0;
}

.grecaptcha-badge {
    display: none !important;
}

.w-checkbox:after {
    display: none !important;
}
.js-mobile-menu-cta .button {
    border-color: var(--extended-green--green-100);
}
