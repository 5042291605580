.accordion-item.active {
    border-bottom: 1px solid #c3c8cd;
}
.accordion-item .accordion-header::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 0;
    height: 1px;
    background-color: black;
}

.accordion-item.active .accordion-header::before {
    width: 100%;
    height: 1px;
    transition: all 10s linear;
}

@media (max-width: 992px) {
    .accordion-item.active .accordion-header::before {
        transition: all 2s linear;
    }
}
