.tooltip-content {
    opacity: 0;
    transition: 0.5s ease;
    transform: translate(-50%, -110%);
}

.tooltip-content::before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0.625rem;
    border-style: solid;
    border-color: #f7f7f7 transparent transparent transparent;
    z-index: 10;
}
.tooltip-content::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0.75rem;
    border-style: solid;
    border-color: #e4e7ed transparent transparent transparent;
}
.tooltip-wrapper:hover .tooltip-content {
    opacity: 1;
    transform: translate(-50%, -100%);
}
